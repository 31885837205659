import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["selectAll", "checkbox", "submitButton"]

  connect() {
    this.updateSubmitButton()
  }

  toggleAll() {
    const checked = this.selectAllTarget.checked
    this.checkboxTargets.forEach(checkbox => checkbox.checked = checked)
    this.updateSubmitButton()
  }

  toggleOne() {
    this.selectAllTarget.checked = this.checkboxTargets.every(checkbox => checkbox.checked)
    this.updateSubmitButton()
  }

  updateSubmitButton() {
    const hasSelection = this.checkboxTargets.some(checkbox => checkbox.checked)
    this.submitButtonTarget.disabled = !hasSelection
  }
}
